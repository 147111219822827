<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Vendor",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Vendor/Konsultan",
      items: [
        {
          text: "Monitoring",
          href: "/",
        },
        {
          text: "Vendor",
          active: true,
        },
      ],
      // variable Page Table
      role_slug: Cookies.get("session_role_slug"),
      loadingTable: true,
      table_data: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      selected: [],

      // Filter Data
      tipe_filter: "",
      pic_filter: [],
      status_filter: "",
      tanggal_filter: "",
      search: "",
      master_user: [],
    };
  },
  mounted() {
    this.getDataTable();
    this.getUser();
  },
  methods: {
    getDataTable() {
      let self = this;

      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/monitoring/vendor?tipe=" +
            self.tipe_filter +
            "&pic=" +
            JSON.stringify(self.pic_filter) +
            "&status=" +
            self.status_filter +
            "&tanggal=" +
            self.tanggal_filter +
            "&search=" +
            self.search
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data.data;
            //console.log(response_data.list_data.data);
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getUser() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/user/pic")
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    hapusData(id) {
      var alert_text = "Apakah Anda yakin ?";
      ("Apakah Anda yakin?");
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/monitoring/vendor/hapus",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman monitoring vendor segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
    //filter data
    filterData() {
      this.getDataTable();
    },
    //multiple delete
    selectItem() {
      let self = this;
      console.log(self.selected);
    },
    //delete data multiple
    deleteselected() {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", self.selected);
          var config = {
            method: "POST",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/monitoring/vendor/multiplehapus",
            data: data,
          };
          axios(config).then((response) => {
            console.log(response);
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data berhasil dihapus.",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getDataTable();
              }
            });
          });
        } else {
          Swal.close();
        }
      });
    },
    eksporHandle() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/monitoring/vendor/exportexcel?tipe=" +
          self.tipe_filter +
          "&pic=" +
          JSON.stringify(self.pic_filter) +
          "&status=" +
          self.status_filter +
          "&tanggal=" +
          self.tanggal_filter;
      link.setAttribute("target", "_blank");
      link.click();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <div class="row" style="margin-bottom: 10px">
                        <div class="col-md-12">
                          <table>
                            <tr>
                              <th style="width: 13%">Tipe</th>
                              <th style="width: 15px">&nbsp;</th>
                              <th style="width: 25%">PIC</th>
                              <th style="width: 15px">&nbsp;</th>
                              <th style="width: 13%">Status</th>
                              <th style="width: 15px">&nbsp;</th>
                              <th style="width: 13%">Tanggal</th>
                              <th style="width: 15px">&nbsp;</th>
                              <th>&nbsp;</th>
                            </tr>
                            <tr>
                              <td>
                                <select
                                  class="form-control input-sm"
                                  v-model="tipe_filter"
                                >
                                  <option value="">Semua Tipe</option>
                                  <option value="consultant">Consultant</option>
                                  <option value="vendor">Vendor</option>
                                </select>
                              </td>
                              <td>&nbsp;</td>
                              <td style="width: 150px">
                                <v-select
                                  placeholder="-Pilih PIC-"
                                  :options="master_user"
                                  label="name"
                                  v-model="pic_filter"
                                  multiple
                                ></v-select>
                              </td>
                              <td>&nbsp;</td>
                              <td style="width: 130px">
                                <select
                                  class="form-control input-sm"
                                  v-model="status_filter"
                                >
                                  <option value="">Semua Status</option>
                                  <option value="1">Terlaksana</option>
                                  <option value="12">Tidak Terlaksana</option>
                                </select>
                              </td>
                              <td>&nbsp;</td>
                              <td style="width: 100px">
                                <input
                                  type="date"
                                  class="form-control"
                                  v-model="tanggal_filter"
                                />
                              </td>
                              <td>&nbsp;</td>
                              <td>
                                <button
                                  class="btn btn-primary"
                                  @click="filterData"
                                  type="button"
                                  style="border-radius: 0px"
                                >
                                  <i class="fa fa-filter"></i> Filter
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 text-end">
                          <button
                            class="btn btn-success m-1 rounded-0"
                            type="button"
                            @click="eksporHandle"
                          >
                            <i class="fas fa-file-excel"></i> Export Data
                          </button>
                          <router-link
                            :to="{ name: 'add-vendor' }"
                            class="btn btn-info rounded-0"
                            type="button"
                          >
                            <i class="fa fa-plus"></i> Tambah Vendor/Konsultan
                          </router-link>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
                <br />
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table mb-0 table-bordered table-condensed table-hover table-striped"
                  >
                    <thead>
                      <tr>
                        <th class="text-center text-white" style="width: 50px;background-color: #132d4a">#</th>
                        <th class="text-center text-white" style="width: 50px;background-color: #132d4a">No</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Nama Vendor/Konsultan</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Tipe</th>

                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Tanggal Mulai Pelaksanaan</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">
                          Tanggal Selesai Pelaksanaan
                        </th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Penjelasan Singkat</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">Penilaian</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a">PIC</th>
                        <th class="text-center text-white" style="width: 100px;background-color: #132d4a">Status</th>
                        <th class="text-center text-white" style="width: 175px;background-color: #132d4a">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="11">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td class="text-center" colspan="11">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          <input
                            type="checkbox"
                            v-model="selected"
                            @change="selectItem"
                            :value="row_data.id"
                          />
                        </td>
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td>
                          {{ row_data.nama }}
                        </td>
                        <td>{{ row_data.tipe_nama }}</td>
                        <td>{{ row_data.tanggal }}</td>
                        <td>{{ row_data.tanggal_selesai }}</td>
                        <td>{{ row_data.penjelasan_singkat }}</td>
                        <td>{{ row_data.penilaian_hasil }}</td>
                        <td>
                          <ol>
                            <li
                              v-for="(item, key_items) in JSON.parse(
                                row_data.pic
                              )"
                              :key="item"
                            >
                              {{ key_items + 1 }}. {{ item.name }}
                            </li>
                          </ol>
                        </td>
                        <td class="text-center">
                          <div v-if="row_data.is_kegiatan_terlaksana == 1">
                            <div
                              class="badge badge-pill badge-soft-success font-size-12"
                            >
                              Terlaksana
                            </div>
                          </div>
                          <div v-else>
                            <div
                              class="badge badge-pill badge-soft-danger font-size-12"
                            >
                              Tidak Terlaksana
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="btn-group" role="group">
                            <router-link
                              :to="{
                                name: 'edit-vendor',
                                params: { id: row_data.id },
                              }"
                              class="btn btn-sm btn-info"
                              ><i class="fa fa-edit"></i> Edit</router-link
                            >
                            <!-- <router-link
                              :to="{
                                name: 'detail-vendor',
                                params: { id: row_data.id },
                              }"
                              class="btn btn-sm btn-primary"
                              ><i class="fa fa-eye"></i> Detail</router-link
                            > -->
                            <button
                              v-if="role_slug == 'super_admin'"
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-on:click="hapusData(row_data.id)"
                            >
                              <i class="fa fa-trash"></i> Hapus
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  href="#"
                  class="btn btn-danger m-1"
                  @click="deleteselected"
                >
                  <i class="fa fa-trash"></i> Hapus Data Terpilih
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
